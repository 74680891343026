import React from "react"
import * as theme from "../../theme"
import { BreadCrumbs, Page, SectionHeader } from "../../components/page"
import headerImage from "./section-header.jpg"
import Layout from "../../layout"
import { LinkButton } from "../../components/button"
import styled from "@emotion/styled"
import stones from "./stones.svg"
import window from "./window.svg"
import glassBlowing from "./glass-blowing.svg"
import { ActivityBox } from '../../components/boxes'
import tools from './tools.svg'
import worksheets from './worksheets.svg'
import { withPrefix } from "gatsby"

const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-bottom: 0.1em solid #efe7e0;
  padding: 0 0 5rem;

  &:first-child {
    margin-top: 3rem;
  }

  @media(max-width: 40rem) {
    flex-direction: column;

    div {
      ${props => props.alternate ? 'order: 3;' : ''}
    }
  }

  h2 {
    font-size: 1.7em;
  }

  p {
    line-height: 1.6;
  }

  a {
    margin-top: 1em;
  }

  img {
    max-height: 20rem;
    width: 30%;
    ${props => props.alternate ? 'order: 2;' : ''}
  }
  div {
    padding: 0 2rem;
    max-width: 25rem;
  }
`

const HowDidTheyBuildThat = () => {
  return (
    <Layout title="How did they build that?">
      <SectionHeader color={theme.colors.yellow.dark} image={headerImage}>
        How did they build that?
      </SectionHeader>
      <BreadCrumbs items={[
        { name: 'How did they build that?' }
      ]} />
      <Page>
        <Item>
          <img src={tools} alt="Illustration of tools" />
          <div>
            <h2>Building the Cathedral</h2>
            <p>Canterbury Cathedral has been built, rebuilt, changed and adapted over hundreds of years. The building illustrates the changing style of architecture (the design of buildings) over the whole of the medieval period.</p>
            <LinkButton to="/how-did-they-build-that/building-the-cathedral">
              Find out more
            </LinkButton>
          </div>
        </Item>
        <Item alternate>
          <img src={stones} alt="Illustration of a stones" />
          <div>
            <h2>Stone</h2>
            <p>Canterbury Cathedral is mostly made of stone because it is such a strong material and can be easy to carve. Much of the stone you can see in the building is a limestone that comes from Caen in France.</p>
            <LinkButton to="/how-did-they-build-that/stone">
              Find out more
            </LinkButton>
          </div>
        </Item>
        <Item>
          <img src={window} alt="Illustration of a window" />
          <div>
            <h2>Stained Glass</h2>
            <p>Stained glass is coloured glass joined together with a soft metal called lead. It can contain pictures and patterns and the windows of Canterbury Cathedral contain some of the oldest and most beautiful stained glass in the world.</p>
            <LinkButton to="/how-did-they-build-that/stained-glass">
              Find out more
            </LinkButton>
          </div>
        </Item>
        <Item alternate>
          <img
            src={glassBlowing}
            alt="Illustration of a stages of glass blowing"
          />
          <div>
            <h2>How is glass made?</h2>
            <p>People have been making glass for over 4000 years. The main ingredient in glass is sand however other substances are needed to make the sand easier to melt and also to add colour.</p>
            <LinkButton to="/how-did-they-build-that/glass">
              Find out more
            </LinkButton>
          </div>
        </Item>

        <h2>Activities</h2>
        <ActivityBox
          title="How Did They Build That activity sheets"
          image={
            <img src={worksheets} alt="Worksheets" />
          }
          link={withPrefix('/HowDidTheyBuildThat.pdf')}
        >
          Click here to find a programme of curriculum linked classroom activities and resources to support the ‘How did they build that ’ resource.
        </ActivityBox>

      </Page>
    </Layout>
  )
}

export default HowDidTheyBuildThat;